import { AxiosRequestConfig } from 'axios';
import { encode } from 'js-base64';

import { APIURL } from '@configurations/Settings';
import axios from '@helpers/Http/config';
import TokenStore from '../store/TokenStore';
import ViewAsStore from '../store/ViewAsStore';

function CustomAppTokenInterceptor() {
	axios.interceptors.request.use(
		(config: AxiosRequestConfig) => {
			if (config.url?.includes(APIURL!)) {
				let tokenData = TokenStore.getState().accessToken;
				if (tokenData) {
					if (config.headers) {
						config.headers['Authorization'] = `Bearer ${tokenData}`;

						config.headers['U'] = encode(
							JSON.stringify({
								viewAs: ViewAsStore.getState().viewAsUsername,
								viewAsName: ViewAsStore.getState().viewAsDisplayName,
							})
						);
					}
				}
			}

			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);
}

export default CustomAppTokenInterceptor;
