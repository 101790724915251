import { Components, createTheme } from '@mui/material/styles';
import green from '@mui/material/colors/green';

const palette = {
  primary: {
    light: '#82a7c9',
    main: '#537898',
    dark: '#244c6a',
  },
  secondary: {
    light: '#ff7a63',
    main: '#ff372f',
    dark: '#c4000d',
  },
  
  background: {
    default: '#f2f2f2',
    paper: '#fff'
  },
  text: {
    disabled: 'rgba(0, 0, 0, 1)'
  },
  titleBarBgColor: '#1e242b',
  sectionHeadersBgColor: '#333d47',
  sideMenuBgColor: '#d9d9d6',
  bottomFooterBgColor: '#333d47',
  linkColor: '#23527c',
  formSectionHeaderBgColor: '#6c6c6c',
  formSubSectionHeader: '#939596',
  approvalItemsBorderAndBgColor: '#B3D6EC',
  status: {
    pending: '#fce776'
  }
};

const shape = {
  borderRadius: 0
};

const mixins = {
  toolbar: {
    minHeight: 40
  },
  sideBar: {
    minWidth: 300
  }
};

const typography = {
  fontFamily: '"Montserrat", "sans-serif"'
}

const components : Components = {
  MuiTextField: {
    defaultProps: {
      variant: 'standard'
    }
  },
  MuiSelect: {
    defaultProps: {
      variant: 'standard'
    }
  },
  MuiChip: {
    defaultProps: {
      color: 'primary'
    }
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        color: 'rgba(0, 0, 0, 0.38)',
        '&.Mui-completed': {
          color: green[500]
        },
        '&.Mui-active': {
          color: palette.primary.main
        }
      }
    }
  }
}

const wspTheme = createTheme({
  palette, mixins, typography, components, shape
});

export default wspTheme;