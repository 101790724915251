import { VersionInterceptor} from '@helpers/Interceptors/request';
import { ServerErrorInterceptor } from '@helpers/Interceptors/response';

import { CustomAppTokenInterceptor, CustomAppTokenErrorInterceptor, MsGraphTokenInterceptor, MsGraphTokenErrorInterceptor } from '@auth';

CustomAppTokenInterceptor();
MsGraphTokenInterceptor();
VersionInterceptor();
ServerErrorInterceptor();
CustomAppTokenErrorInterceptor();
MsGraphTokenErrorInterceptor();