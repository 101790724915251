import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';

import theme from 'themes/wsp-theme';

import { ReactComponent as Logo } from './under-construction.svg';

const UnderConstruction = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Stack spacing={4} height="90vh" display="flex" alignItems="center" justifyContent="center">
          <Logo style={{ height: '300px', width: '600px' }} />
          <Typography variant="h4">Forecaster will be launching soon!</Typography>
        </Stack>
      </ThemeProvider>
    </StyledEngineProvider>
    
  );
}

export default UnderConstruction;