import { AxiosRequestConfig } from 'axios';
import { encode } from 'js-base64';

import { MS_GRAPH_API_URL } from '@configurations/Settings';
import axios from '@helpers/Http/config';
import TokenStore from '../store/TokenStore';
import ViewAsStore from '../store/ViewAsStore';


function MsGraphTokenInterceptor(){
  axios.interceptors.request.use((config : AxiosRequestConfig) => {
    if (config.url?.includes(MS_GRAPH_API_URL!)) {
      let tokenData = TokenStore.getState().msGraphToken;
      if(tokenData){
        if (config.headers) {
          config.headers['Authorization'] = `Bearer ${tokenData}`;

          config.headers['U'] = encode(JSON.stringify({
            viewAs: ViewAsStore.getState().viewAsUsername
          }));
        }
      }
    }
    
    return config;
  }, error => {
    return Promise.reject(error);
  });
}

export default MsGraphTokenInterceptor;