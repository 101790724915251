import create from 'zustand';
import { persist } from 'zustand/middleware';

interface ITokenStore {
  accessToken: string,
  msGraphToken: string,
  clearAccessToken: () => void,
  clearMsGraphToken: () => void
}

const TokenStore = create<ITokenStore>()(persist(set => ({
    accessToken: '',
    msGraphToken: '',
    clearAccessToken: () => set({ accessToken: '' }),
    clearMsGraphToken: () => set({ msGraphToken: '' })
  }), 
  {
    name: 'T',
    getStorage: () => localStorage,
    // serialize: (state) => encode(JSON.stringify(state)),
    // deserialize: (str) => JSON.parse(decode(str))
  }
));

export default TokenStore;