import { AxiosRequestConfig } from 'axios';

import axios from '@helpers/Http/config';
import { MS_GRAPH_API_URL } from '@configurations/Settings';
import { APIVERSION } from '@configurations/Settings';

function VersionInterceptor(){
  axios.interceptors.request.use((config : AxiosRequestConfig) => {
    if(!config.url!.includes('appVersion') && !config.url!.includes(MS_GRAPH_API_URL!)){
      if(config.url!.includes('?')){
        config.url = `${config.url}&appVersion=${APIVERSION}`;
      }else{
        config.url = `${config.url}?appVersion=${APIVERSION}`;
      }
    }
    return config;
  }, error => {
    return Promise.reject(error);
  });
}

export default VersionInterceptor;