import { useMsal, useIsAuthenticated  } from '@azure/msal-react';
import { RedirectRequest } from '@azure/msal-browser';

import { MSAL_SCOPE } from '@configurations/Settings';

const msalConfig : RedirectRequest = {
  scopes: MSAL_SCOPE!.split(';')
}

const useAuth = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const login = () => {
    instance.loginRedirect(msalConfig);
  }

  const logout = () => {
    instance.logoutRedirect();
  }

  return {
    login,
    logout,
    isAuthenticated,
    account: accounts[0]
  }
}

export default useAuth;