import { useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMount } from 'react-use';

import usePreviousState from '@helpers/Hooks/usePreviousState';
import LoggedInAsStore from '../store/LoggedInAsStore';
import ViewAsStore from '../store/ViewAsStore';
import useAuth from './useAuth';
import TokenService from '../service/TokenService';

const tokenService = new TokenService();

const useAuthInit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, account } = useAuth();
  const { loggedInAsUsername, loggedInAsDisplayName, setLoggedInAsDisplayName, setLoggedInAsUsername } = LoggedInAsStore();
  const { viewAsUsername, setViewAsUsername, setViewAsDisplayName, viewAsDisplayName } = ViewAsStore();
  const previousIsAuthenticatedState = usePreviousState(isAuthenticated);
  const initialPath = useRef<string>('');

  const getTokens = useCallback(async () => {
    if (isAuthenticated) {
      await tokenService.GetTokenSilently('APP');
      await tokenService.GetTokenSilently('MSGRAPH');
    }
    
  }, [isAuthenticated]);

  const setLoggedInAs = useCallback(async () => {
    if(!loggedInAsUsername && isAuthenticated){
      setLoggedInAsDisplayName(account?.name);
      setLoggedInAsUsername(account?.username);
    }
    
  }, [isAuthenticated, account, setLoggedInAsDisplayName, setLoggedInAsUsername, loggedInAsUsername]);

  useEffect(() => {
    getTokens();
  }, [isAuthenticated, getTokens])

  useEffect(() => {
    if(account.name && account.username){
      setLoggedInAs();
    }
  }, [setLoggedInAs, account]);

  useEffect(() => {
    if(isAuthenticated){
      if(!viewAsUsername || !viewAsDisplayName){
        setViewAsUsername(loggedInAsUsername);
        setViewAsDisplayName(loggedInAsDisplayName);
      }
    }
  }, [viewAsUsername, isAuthenticated, setViewAsUsername, loggedInAsUsername, loggedInAsDisplayName, setViewAsDisplayName, viewAsDisplayName]);


  useEffect(() => {
    if(!isAuthenticated && location.pathname !== '/'){
      navigate('/');
    }
  }, [location, isAuthenticated, navigate]);

  useEffect(() => {
    if(previousIsAuthenticatedState === false && 
        isAuthenticated === true && 
        initialPath.current !== '/'){
      navigate(initialPath.current);
    }

  }, [previousIsAuthenticatedState, isAuthenticated, initialPath, navigate]);

  useMount(() => {
    if(location.pathname !== '/'){
      initialPath.current = location.pathname;
    }
  });
}

export default useAuthInit;