import msalInstance from '@auth/config/msalInstance';
import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import tokenServiceConfig from '../config/tokenServiceConfig';
import TokenStore from '../store/TokenStore';

class TokenService {
  GetTokenSilently = async (type : string) => {
    try {
      console.log('Attempting Silent Token Acquisition...');
      const response = await msalInstance.acquireTokenSilent(tokenServiceConfig[type].requestParams).catch();
      TokenStore.setState({ 
        [tokenServiceConfig[type].tokenKey]: response.accessToken
      });
      return response;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        console.log(error);
        return msalInstance.acquireTokenRedirect(tokenServiceConfig[type].requestParams);
      }
    }
    
  }
}

export default TokenService;